<template>
  <div class="weapp">
    <div class="float">
      <van-button color="#ff976a">去小程序购买</van-button>
    </div>
  </div>
</template>

<script>
import { Button } from 'vant'

export default {
  components: {
    [Button.name]: Button
  }
}
</script>

<style lang="less" scoped>
.weapp {
  position: relative;
  width: 100%;
  height: 100%;
  background: url('/weapp.jpg') no-repeat;
  background-size: 100% 100%;
  > .float {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 25%;
  }
}
</style>
